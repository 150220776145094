<template>
    <div class="modal-dialog login_dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="ms_register_img" style="margin-top: 2vh;">
                            <img src="../../assets/zain_gray.png" alt="" class="img-fluid" />
                        </div>
                        <div class="ms_register_form">
                            <!-- <form  > -->
                            <h2>الغاء الاشتراك</h2>
                            <!-- <a href="profile.html" class="ms_btn" target="_blank" style="width: 80%;">الغاء الاشتراك</a> -->
                                <a @click="unsubscribe()" class="ms_btn pointer" style="width: 80%;">
                                    <span v-if="loading" class="px-1 ">جاري الغاء الاشتراك</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else class="">الغاء الاشتراك</span>
                                </a>
                            <!-- <p>Don't Have An Account? <a href="#myModal" data-toggle="modal" class="ms_modal1 hideCurrentModel">register here</a></p> -->
                            <!-- </form> -->
                        </div>
                    </div>
                </div>
    </div>
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
   name: 'AppUnsubscribes',
   data() {
     return {
       loading: false,
     };
   },
   methods: {
      goBack() {
         return this.$router.go(-1)
      },
      async unsubscribe() {
        const toast = useToast();
          // let button = document.querySelector(".button");
          // button.disabled = true;
          this.loading = true;
        await HTTPDSP.post("DSPUnsubscribe.php?msisdn="+this.$cookie.getCookie("msisdn")).then((response) => {
          if (response.data.status == 0) {
            toast.error("انت لست مشترك في منصة نغم", { timeout: 2000 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          } else {
            toast.success("تم الغاء اشتراكك من منصة نغم ", { timeout: 2000 });
            this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
            this.$router.push('/')
          }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            this.loading = false;
        });
      },
   }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>